import React from "react";
import { domainePineHill, domaineStCome, greatMap, greatMapMobile, investirGroupLogo } from "../assets/images";
import { Col, Container, Image, Row } from "react-bootstrap";

export default function Selection() {
  return (
    <div className="great-map-sec h-100">
      <Container className="position-absolute start-0 end-0" style={{zIndex:"10"}}>
        <div className="welcome-sec mx-auto text-center h-100 vstack" style={{maxWidth:"403px"}}>
          <div className="bg-white p-4 welcome-sec-logo">
            <Image className="head-logo" src= {investirGroupLogo}/>
            <h6 className="font-bold mb-1">Bienvenue</h6>
            <p>Sélectionnez un de nos 2 domaines</p>
          </div>
          {/* <div className="my-auto py-4">
            <Row>
              <Col xs={6} className="pt-4">
                <a className="d-block" href={process.env.REACT_APP_PINE_HILL_ENDPOINT}>
                  <Image className="w-100 cursor-pointer" src= {domainePineHill}/>
                </a>
              </Col>
              <Col xs={6}>
                <a className="d-block" href={process.env.REACT_APP_STCOME_ENDPOINT}>
                  <Image className="w-100 cursor-pointer" src= {domaineStCome}/>
                </a>
              </Col>
            </Row>
          </div> */}
        </div>
      </Container>
      <div className="justify-content-center h-100 map-area-img d-none d-sm-flex align-items-center">
        <div className="text-center">
          <Image src={greatMap} usemap="#image-map"/>
        </div>
      </div>
      <map name="image-map">
        <area className="cursor-pointer" alt="STCOME ENDPOINT" title="PINE HILL ENDPOINT" href={process.env.REACT_APP_PINE_HILL_ENDPOINT} coords="507,778,494,655,634,636,666,789" shape="poly"/>
        <area className="cursor-pointer" alt="STCOME ENDPOINT" title="STCOME ENDPOINT" href={process.env.REACT_APP_STCOME_ENDPOINT} coords="676,617,647,482,786,480,798,594" shape="poly"/>
        <area className="cursor-pointer" alt="" title="test" href="#" coords="835,556,956,584,940,719,812,689" shape="poly"/>
      </map>

      <div className="vstack justify-content-center map-area-img h-100 d-flex d-sm-none">
        <div className="text-center">
          <Image src={greatMapMobile} usemap="#greatMapMobile" />
        </div>
      </div>
      <map name="greatMapMobile" className="d-sm-none">
        <area className="cursor-pointer" alt="PINE HILL ENDPOINT" title="PINE HILL ENDPOINT" href={process.env.REACT_APP_PINE_HILL_ENDPOINT} coords="115,625,138,687,116,738,47,753,-1,698,8,639,60,608" shape="poly"/>
        <area className="cursor-pointer" alt="STCOME ENDPOINT" title="STCOME ENDPOINT" href={process.env.REACT_APP_STCOME_ENDPOINT} coords="166,534,234,526,276,554,281,599,267,625,216,657,142,613" shape="poly"/>
        <area className="cursor-pointer" alt="" title="test" href="#" coords="250,707,315,700,340,751,358,782,339,827,274,843,216,797,228,748" shape="poly"/>
      </map>
    </div>
  );
}
