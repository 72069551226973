import React, { useEffect, useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { useLocation } from "react-router-dom";
import { onValue, query, orderByChild, equalTo } from "firebase/database";
import { dealsRef, projectRef } from "../../../services/firebase";
import { Location1, Location2 } from "../../mapSVGs/pineHill/location";
import {
  Header,
  Footer,
  PineMainMap,
  PinePhase1,
  PinePhase2,
  PinePhase3,
  PinePhase4,
  Menu,
  ViewMore,
  LandReserch,
  Loader,
  LoginForm,
  ForgetPassword,
  // RegisterForm,
  SignupForm,
  Favourite,
  Promises,
  Spinner,
  NewLogin,
  PinePhase4Nord,
} from "../../index";
import {
  areaSet,
  phase2AreaSet,
  phase3AreaSet,
  phase4AreaSet,
  phase4NordAreaSet,
} from "../../../utils/data";
import { colors } from "../../../utils/theme";
import { filterString, selectPipeline } from "../../../utils/helper";
import { constant } from "../../../utils/constant";
import strings from "../../../utils/fr.json";
import { areaRule } from "./areaRule";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const PineMapMain = () => {
  const { setIdentity, setPathPageView, setTrackPageView } = useTrackingCode();
  var location = useLocation();
  const [loading, setLoading] = useState(false);
  // const [isMenuPage, setIsMenuPage] = useState(false);
  const phases = [
    { y: 820, x: 615, phase: PinePhase1, name: constant.phase1 },
    { y: 1246, x: 543, phase: PinePhase2, name: constant.phase2 },
    { y: 1078, x: 2992, phase: PinePhase3, name: constant.phase3 },
    { y: 750, x: 2950, phase: PinePhase4, name: constant.phase4 },
    { y: 475, x: 2960, phase: PinePhase4Nord, name: constant.phase4nord },
  ];
  const [value, setValue] = useState({
    scale: 0.5,
    translation: { x: -200, y: -100 },
  });

  const [disponibles, setDisponibles] = useState(0);
  const loginAlready = localStorage.getItem("@login");
  const [footerActive, setFooterActive] = useState(0);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [phase, setPhase] = useState(1);
  const [isLoad, setIsLoad] = useState(location?.state?.isBack ? false : true);
  const [isResearchModal, setIsResearchModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(!(loginAlready === "true"));
  const [isForgotPassModal, setIsForgotPassModal] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(false);
  const [isPromiseModal, setIsPromiseModal] = useState(false);
  const [isFavouriteModal, setIsFavouriteModal] = useState(false);
  const [dateLimit, setDateLimit] = useState();
  const [timeLimit, setTimeLimit] = useState();
  const [totalLand, setTotalLand] = useState([]);
  const [prefillEmail, setPreFillEmail] = useState("");
  var isGuestLogin = localStorage.getItem("@guest");

  useEffect(() => {
    // window.addEventListener("resize", mapResize);
    window.addEventListener("load", mapResize);
    setTimeout(() => {
      setIsLoad(false);
    }, 1500);
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataString = await localStorage.getItem("@userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const userEmail = userData.email;
          setIdentity(userEmail);
          setPathPageView(`${location?.pathname}`);
          setTrackPageView();
        }
        // Rest of your code
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData(); // Call the function inside useEffect
  }, [loginAlready]);

  const mapResize = () => {
    const screenWidth = window.screen.width;
    if (screenWidth < 576) {
      //Mobile
      setValue({
        scale: 0.6,
        translation: { x: -1650, y: -180 },
      });
    } else if (screenWidth >= 576 && screenWidth < 768) {
      //Small
      setValue({
        scale: 0.5,
        translation: { x: -1200, y: -150 },
      });
    } else if (screenWidth >= 768 && screenWidth < 992) {
      //Medium
      setValue({
        scale: 0.6,
        translation: { x: -1500, y: -250 },
      });
    } else if (screenWidth >= 992 && screenWidth < 1200) {
      //Large
      setValue({
        scale: 0.26,
        translation: { x: 0, y: 0 },
      });
    } else if (screenWidth >= 1200 && screenWidth < 1400) {
      // X Large
      setValue({
        scale: 0.3,
        translation: { x: 40, y: 0 },
      });
    } else if (screenWidth >= 1400 && screenWidth < 1600) {
      // 2X Large
      setValue({
        scale: 0.37,
        translation: { x: 0, y: 0 },
      });
    } else if (screenWidth >= 1600) {
      // 3X Large
      setValue({
        scale: 0.5,
        translation: { x: 0, y: 0 },
      });
    }
  };

  const getDealsData = async (phaseType, ind) => {
    setLoading(true);
    const starCountRef = await dealsRef;
    onValue(
      starCountRef,
      async (snapshot) => {
        const newDealsData = snapshot.val();
        var newData = [];
        var totalLands = [];
        var dealsInfo = await Object.values(newDealsData)?.filter(
          (item) =>
            item?.properties?.pipeline?.value ===
            selectPipeline(phaseType, constant.pinehill)
        );

        getPipelineData(dealsInfo[1]);
        var areaData =
          phaseType === constant.phase1
            ? areaSet
            : phaseType === constant.phase2
            ? phase2AreaSet
            : phaseType === constant.phase3
            ? phase3AreaSet
            : phaseType === constant.phase4
            ? phase4AreaSet
            : phase4NordAreaSet;

        for (let index = 0; index < areaData.length; index++) {
          const element = areaData[index];
          var data = [];
          let color = colors.grey;

          for (let i = 0; i < dealsInfo.length; i++) {
            const deal = dealsInfo[i];
            var newPhase1Value = deal?.properties?.dealname?.value.substr(4, 1),
              newPhase2Value = deal?.properties?.dealname?.value
                .substr(0, 5)
                .trim(),
              newPhase4Value = deal?.properties?.dealname?.value
                .substr(4, 3)
                .trim(),
              newPhase4NordValue = deal?.properties?.dealname?.value
                .substr(4, 4)
                .trim();
            const identification =
              phaseType === constant.phase4 || phaseType === constant.phase4nord
                ? filterString(
                    deal?.properties?.identification_du_lot_ph?.value
                  )
                    .substr(4, 3)
                    .trim()
                : filterString(
                    deal?.properties?.identification_du_lot_ph?.value
                  );

            var checkCondition =
              identification ||
              (phaseType === constant.phase1
                ? newPhase1Value
                : phaseType === constant.phase2 || phaseType === constant.phase3
                ? newPhase2Value
                : phaseType === constant.phase4
                ? newPhase4Value
                : newPhase4NordValue);

            if (
              element.title?.toLocaleLowerCase() ===
              checkCondition?.toLocaleLowerCase()
            ) {
              data = [...data, deal];
            }
          }

          const checkStatus =
            data[0]?.properties?.statut_des_promesses_d_achat?.value;
          const reservationStatus =
            data[0]?.properties?.reservation_via_la_carte_interactive?.value;

          const dealStageValue = data[0]?.properties.dealstage?.value;
          const stageValue =
            constant.dealsStage.pinehill[phaseType.replace(/ +/g, "")];
          color = areaRule(
            checkStatus,
            reservationStatus,
            dealStageValue,
            stageValue
          );

          var count = checkStatus
            ? checkStatus === strings.Status.complete
              ? 3
              : checkStatus === strings.Status.row2
              ? 2
              : 1
            : 0;
          totalLands =
            data.length > 0 ? [...totalLands, { colors: color }] : totalLands;
          newData = [
            ...newData,
            {
              ...element,
              colors: color,
              deals: data,
              count: count,
            },
          ];

          var disponibles = newData.filter(
            (item) =>
              item.colors === colors.green ||
              // item.colors === colors.yellow ||
              item.colors === colors.green3
          );
          setTotalLand(
            phaseType === constant.phase2
              ? phase2AreaSet.filter((d) => d.title)
              : totalLands
          );
          setDisponibles(disponibles.length);
          // setOpenPhaseModal(!openPhaseModal);
          setPhase(phaseType === constant.phase4nord ? "4nord" : ind + 1);
          setLoading(false);
        }
      },
      { onlyOnce: true }
    );
  };

  const getPipelineData = (deals) => {
    const queryData = query(
      projectRef,
      orderByChild("pipelineId"),
      equalTo(deals.pipelineId)
    );
    onValue(queryData, async (snapshot) => {
      var projectsData = snapshot.val() || {};
      projectsData = Object.values(projectsData) || [];
      setTimeout(() => {
        setOpenPhaseModal(!openPhaseModal);
      }, 500);
      if (projectsData.length > 0) {
        setDateLimit(projectsData[0]?.date_limit);
        setTimeLimit(projectsData[0]?.time_limit);
      }
    });
  };

  const setFooterHandle = (index) => {
    setFooterActive(index);
    // if (index === 0) {
    //   toggleResearchModal();
    //   setIsFavouriteModal(false);
    //   setIsPromiseModal(false);
    // } else
    if (index === 0) {
      togglePromiseModal();
      setIsFavouriteModal(false);
      setIsResearchModal(false);
    } else {
      toggleFavouriteModal();
      setIsPromiseModal(false);
      setIsResearchModal(false);
    }
    // setIsResearchModal(index === 1 ? !isResearchModal : false);
  };

  const toggleFavouriteModal = async () => {
    setIsFavouriteModal(true);
  };

  const togglePromiseModal = async () => {
    setIsPromiseModal(!isPromiseModal);
  };

  const togglePromiseModal1 = async () => {
    setIsPromiseModal(!isPromiseModal);
  };

  // const toggleResearchModal = () => {
  //   setIsResearchModal(!isResearchModal);
  // };

  const toggleLogin = () => {
    setIsLoginModal(!isLoginModal);
  };

  const handleLogin = () => {
    toggleLogin();
    localStorage.setItem("@login", true);
  };

  const handleForgotPassword = () => {
    toggleLogin();
    toggleForgotPass();
  };

  const toggleForgotPass = () => {
    setIsForgotPassModal(!isForgotPassModal);
  };

  const toggleRegisterForm = () => {
    setIsRegisterForm(!isRegisterForm);
  };

  const handleRegisterForm = (email = "") => {
    toggleLogin();
    toggleRegisterForm();
    if (email) {
      setPreFillEmail(email);
    }
  };

  const handleLogout = () => {
    setIsLoginModal(true);
  };

  if (isLoad) {
    return <Loader />;
  } else {
    return (
      <>
        <Spinner loading={loading} />
        <div style={{ backgroundColor: "#8EA385" }}>
          <Header handleLogout={handleLogout} />
          <MapInteractionCSS
            value={value}
            onChange={(value) => {
              value.scale < 2
                ? setValue(value)
                : setValue({ scale: 2, translation: value.translation });
            }}
            minScale={0.125}
          >
            <div>
              <PineMainMap />
              {phases.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    style={{
                      position: "absolute",
                      top: item.y,
                      left: item.x,
                    }}
                    onClick={() => {
                      getDealsData(item.name, index);
                      // setOpenPhaseModal(!openPhaseModal);
                      // setPhase(index + 1);
                    }}
                    onTouchEnd={() => {
                      getDealsData(item.name, index);
                      // setOpenPhaseModal(!openPhaseModal);
                      // setPhase(index + 1);
                    }}
                  >
                    <item.phase />
                  </div>
                );
              })}

              <div
                style={{
                  position: "absolute",
                  top: 480,
                  left: 250,
                }}
              >
                <Location1 />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 340,
                  left: 300,
                }}
              >
                <Location2 />
              </div>
            </div>
          </MapInteractionCSS>
        </div>
        {/* {isMenuPage ? (
          <Menu
            show={isMenuPage}
            handleMenu={() => setIsMenuPage(!isMenuPage)}
          />
        ) : null} */}
        {openPhaseModal ? (
          <ViewMore
            show={openPhaseModal}
            handlePhases={() => setOpenPhaseModal(!openPhaseModal)}
            phase={phase}
            zoom={value.scale}
            disponibles={disponibles}
            totalLand={totalLand.length}
            timeLimit={timeLimit}
            dateLimit={dateLimit}
          />
        ) : null}

        {isResearchModal ? (
          <LandReserch
            show={isResearchModal}
            handleResearch={() => setIsResearchModal(!isResearchModal)}
          />
        ) : null}
        {isPromiseModal ? (
          <Promises
            show={isPromiseModal}
            togglePromiseModal={togglePromiseModal1}
            closePromiseModal={() => setIsPromiseModal(false)}
          />
        ) : null}
        {isFavouriteModal ? (
          <Favourite
            show={isFavouriteModal}
            toggleFavouriteModal={toggleFavouriteModal}
            location={location}
            closeFavouriteModal={() => setIsFavouriteModal(false)}
          />
        ) : null}
        {isLoginModal ? (
          <NewLogin
            show={isLoginModal}
            toggleLogin={toggleLogin}
            prefillEmail={prefillEmail}
            handleForgotPassword={handleForgotPassword}
            handleRegisterForm={handleRegisterForm}
            handleLogin={handleLogin}
            handleLoader={(isOn) => setLoading(isOn)}
            handlePrefillEmail={() => setPreFillEmail("")}
          />
        ) : null}
        {isForgotPassModal ? (
          <ForgetPassword
            show={isForgotPassModal}
            toggleForgotPass={toggleForgotPass}
            handleForgotPassword={handleForgotPassword}
          />
        ) : null}
        {/* {isRegisterForm ? (
          <RegisterForm
            show={isRegisterForm}
            toggleRegisterForm={toggleRegisterForm}
            handleRegisterForm={handleRegisterForm}
            handleLoader={(isOn) => setLoading(isOn)}
          />
        ) : null} */}
        {isRegisterForm ? (
          <SignupForm
            show={isRegisterForm}
            toggleRegisterForm={toggleRegisterForm}
            handleRegisterForm={handleRegisterForm}
            handleLoader={(isOn) => setLoading(isOn)}
          />
        ) : null}
        {isGuestLogin !== "true" && footerActive ? (
          <Footer
            footerActive={footerActive}
            setFooterActive={setFooterHandle}
          />
        ) : null}
      </>
    );
  }
};

export default PineMapMain;
