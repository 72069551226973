export const C65 = ({ width, height }) => {
  return (
    <svg
      width="37"
      height="29"
      viewBox="0 0 37 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.9611 4.09631L33.8184 29.0003L0.414429 25.131L3.42376 0.0123062L36.9611 4.09631Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C66 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1601 0.616379L41.3548 17.9084L18.4388 42.519L-0.00787354 25.2057L23.1601 0.616379Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C67 = ({ width, height }) => {
  return (
    <svg
      width="30"
      height="37"
      viewBox="0 0 30 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78345 0.0139046L29.7061 2.99791L26.0514 36.4259L0.912781 33.5766L4.78345 0.0139046Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C68 = ({ width, height }) => {
  return (
    <svg
      width="40"
      height="34"
      viewBox="0 0 40 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.459 0.10627L39.767 24.4023L7.30704 33.1849L0.795044 8.73827L33.459 0.10627Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C69 = ({ width, height }) => {
  return (
    <svg
      width="40"
      height="34"
      viewBox="0 0 40 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.112 0.213326L39.42 24.5093L6.96 33.292L0.447998 8.84533L33.112 0.213326Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C70 = ({ width, height }) => {
  return (
    <svg
      width="36"
      height="29"
      viewBox="0 0 36 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.9748 3.73376L33.6268 28.7244L0.11615 25.9218L2.32415 0.719093L35.9748 3.73376Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C71 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.7904 15.2472L30.5851 37.7086L0.342407 23.0046L11.4944 0.295223L41.7904 15.2472Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C72 = ({ width, height }) => {
  return (
    <svg
      width="43"
      height="38"
      viewBox="0 0 43 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1835 15.4663L30.9781 37.9277L0.735474 23.2237L11.8875 0.514339L42.1835 15.4663Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C73 = ({ width, height }) => {
  return (
    <svg
      width="39"
      height="31"
      viewBox="0 0 39 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1728 0.282562L38.0848 25.0772L4.92348 30.6572L0.823486 5.6919L34.1728 0.282562Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C74 = ({ width, height }) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1954 0.650757L42.6274 20.4468L16.3194 41.3921L0.640747 21.5374L27.1954 0.650757Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C75 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8935 0.658688L41.5188 17.4854L19.2321 42.6667L0.353455 25.8254L22.8935 0.658688Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C76 = ({ width, height }) => {
  return (
    <svg
      width="39"
      height="42"
      viewBox="0 0 39 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3527 0.424061L38.014 11.2174L23.866 41.7241L0.958008 30.9894L15.3527 0.424061Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C77 = ({ width, height }) => {
  return (
    <svg
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.869865 0.043541L25.9699 0.30621L25.9605 33.9342L0.661865 33.8275L0.869865 0.043541Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C78 = ({ width, height }) => {
  return (
    <svg
      width="32"
      height="38"
      viewBox="0 0 32 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.638489 4.77297L25.3505 0.374306L31.5825 33.4183L6.70382 38.009L0.638489 4.77297Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C79 = ({ width, height }) => {
  return (
    <svg
      width="33"
      height="39"
      viewBox="0 0 33 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.486572 5.04356L25.0999 0.122223L32.0306 33.0276L7.25324 38.1436L0.486572 5.04356Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C80 = ({ width, height }) => {
  return (
    <svg
      width="33"
      height="39"
      viewBox="0 0 33 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0811157 6.00035L24.5451 0.381687L32.4078 33.0764L7.78645 38.895L0.0811157 6.00035Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C81 = ({ width, height }) => {
  return (
    <svg
      width="33"
      height="39"
      viewBox="0 0 33 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.565002 5.72423L25.029 0.105564L32.8917 32.8002L8.27034 38.6189L0.565002 5.72423Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C82 = ({ width, height }) => {
  return (
    <svg
      width="33"
      height="40"
      viewBox="0 0 33 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.207336 6.213L24.6713 0.594334L32.534 33.289L7.91267 39.1077L0.207336 6.213Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C83 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.512268 16.2191L20.2856 0.757774L41.2696 27.0351L21.4376 42.7431L0.512268 16.2191Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C84 = ({ width, height }) => {
  return (
    <svg
      width="40"
      height="43"
      viewBox="0 0 40 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1819 42.1469L0.584534 29.3562L17.4232 0.248184L39.2725 13.0028L22.1819 42.1469Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C85 = ({ width, height }) => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.4027 25.2867L24.2561 42.6294L0.774719 18.5587L18.9547 0.964062L42.4027 25.2867Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C86 = ({ width, height }) => {
  return (
    <svg
      width="40"
      height="43"
      viewBox="0 0 40 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1133 42.7898L0.51593 29.9991L17.3546 0.891129L39.2039 13.6458L22.1133 42.7898Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C87 = ({ width, height }) => {
  return (
    <svg
      width="34"
      height="40"
      viewBox="0 0 34 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.09552 6.51011L24.3449 0.0261078L33.3635 32.4208L8.96485 39.1101L0.09552 6.51011Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C88 = ({ width, height }) => {
  return (
    <svg
      width="40"
      height="43"
      viewBox="0 0 40 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2057 0.884258L39.671 13.8963L22.5337 42.8296L0.817688 29.8509L18.2057 0.884258Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C89 = ({ width, height }) => {
  return (
    <svg
      width="29"
      height="37"
      viewBox="0 0 29 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.53009 2.77282L25.5554 0.826157L28.5061 34.3235L3.29542 36.4448L0.53009 2.77282Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C90 = ({ width, height }) => {
  return (
    <svg
      width="29"
      height="37"
      viewBox="0 0 29 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.481995 2.9071L25.5073 0.960434L28.458 34.4578L3.24733 36.5791L0.481995 2.9071Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C91 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="37"
      viewBox="0 0 42 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.707825 23.1045L10.7732 0.109825L41.7145 13.2778L31.7158 36.5178L0.707825 23.1045Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C92 = ({ width, height }) => {
  return (
    <svg
      width="37"
      height="29"
      viewBox="0 0 37 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.06326 28.4587L0.800598 3.46007L34.2579 0.0867424L36.6979 25.2681L3.06326 28.4587Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C93 = ({ width, height }) => {
  return (
    <svg
      width="37"
      height="30"
      viewBox="0 0 37 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.40531 29.1924L0.142639 4.19372L33.6 0.820385L36.0386 26.0017L2.40531 29.1924Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C94 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="37"
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.3384 0.784958L40.6757 24.085L9.59035 36.9116L0.03302 13.4876L31.3384 0.784958Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C95 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.00189209 15.3396L20.5408 0.915573L40.1448 28.2369L19.5314 42.9049L-0.00189209 15.3396Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C96 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1847 0.0312347L41.4447 22.4646L11.5474 37.8566L0.0567017 15.3179L30.1847 0.0312347Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C97 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="37"
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8537 0.637642L40.7724 24.1003L9.46441 36.371L0.325745 12.779L31.8537 0.637642Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C98 = ({ width, height }) => {
  return (
    <svg
      width="37"
      height="42"
      viewBox="0 0 37 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.521301 10.0446L23.7666 0.57127L36.7746 31.5806L13.408 41.2753L0.521301 10.0446Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C99 = ({ width, height }) => {
  return (
    <svg
      width="37"
      height="42"
      viewBox="0 0 37 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9074 0.74128L36.9181 10.7706L23.7981 41.7333L0.542053 31.7706L13.9074 0.74128Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C100 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.263 14.5531L20.7803 0.0930862L40.4323 27.3811L19.8443 42.0851L0.263 14.5531Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C101 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0491333 22.8099L12.2411 0.86853L41.8011 16.9005L29.6518 39.0925L0.0491333 22.8099Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C102 = ({ width, height }) => {
  return (
    <svg
      width="37"
      height="29"
      viewBox="0 0 37 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.749084 25.0813L3.48108 0.129272L36.9437 3.44661L34.3491 28.6119L0.749084 25.0813Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C103 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.708069 14.1405L21.5414 0.140465L40.5827 27.8565L19.6734 42.1005L0.708069 14.1405Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C104 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.256409 14.3377L21.0897 0.33773L40.1311 28.0537L19.2217 42.2977L0.256409 14.3377Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C105 = ({ width, height }) => {
  return (
    <svg
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.819458 14.6869L21.6528 0.686852L40.6941 28.4029L19.7848 42.6469L0.819458 14.6869Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C106 = ({ width, height }) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7786 0.286499L42.3426 18.2185L18.5626 41.9932L0.746643 24.0318L24.7786 0.286499Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C107 = ({ width, height }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0769 0.0275879L41.6409 17.9596L17.8609 41.7343L0.0449219 23.7729L24.0769 0.0275879Z"
        fill="#985735"
      />
    </svg>
  );
};

export const C108 = ({ width, height }) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9238 0.186157L42.4892 18.1182L18.7078 41.8928L0.891846 23.9315L24.9238 0.186157Z"
        fill="#985735"
      />
    </svg>
  );
};
